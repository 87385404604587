<template>
  <div class="box" style="">
    <div class="left">
      <el-button type="primary" @click="handlePrev">上一题</el-button>
      <el-button type="primary" @click="handleNext">下一题</el-button>
    </div>
    <div class="right">
      <el-button type="warning" :icon="buttonIcon" plain @click="handleMarkers">{{
        buttonText }}</el-button>
      <el-button type="warning" plain @click="counter">计算器</el-button>
    </div>
    <!-- 在线计算器 -->
    <div v-if="showCounter" class="calculator-draggable" ref="calculatorDraggable" :style="calculatorStyle"
      @mousedown="handleDragStart" @mousemove="handleMouseMove" @mouseup="handleMouseUp">
      <iframe ref="calculatorFrame" src="https://www.matools.com/calculator?embed"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'belowBtn',
  components: {},
  props: {
    dataSource: {
      type: Array
    },
    // 标记
    markers: {
      type: Boolean
    }
  },
  data() {
    return {
      buttonText: '标记',
      buttonIcon: 'el-icon-star-off',
      localMarkers: this.markers,
      showCounter: false, // 在线计算器的显示与隐藏
      isDragging: false,
      initialX: 0,
      initialY: 0,
      calculatorDraggable: null,
      calculatorStyle: {
        width: '490px',
        height: '333px',
        left: '80%',
        top: '70%'
      },
    }
  },
  mounted() {
    // 监听一个自定义事件
    this.$bus.$on('event', (item) => {
      this.item = item;
    });
    // 添加监听器
    // 鼠标点击才可拖动计算器
    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleMouseUp);
  },
  updated() {
    if (!this.calculatorDraggable) {
      this.$nextTick(() => {
        this.calculatorDraggable = this.$refs.calculatorDraggable;
      });
    }
  },
  beforeDestory() {
    // 鼠标点击才可拖动计算器
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
  },
  beforeDestory() {
    this.$bus.$off('event')
  },
  methods: {
    // 上一题
    handlePrev() {
      this.$emit('prev-clicked');
    },
    // 下一题
    handleNext() {
      this.$emit('next-clicked');
    },
    character() {
      console.log('特殊字符富文本');

    },
    // 标记
    handleMarkers() {
      this.localMarkers = !this.localMarkers;
      this.$emit('markers-updated', this.localMarkers);
      this.$bus.$emit('btnStyle', { style: this.buttonIcon, text: this.buttonText })
      // this.$bus.$emit('marker-updated', this.localMarkers);
      if (this.localMarkers) {
        this.buttonIcon = 'el-icon-star-on';
        this.buttonText = '取消标记';
      } else {
        this.buttonIcon = 'el-icon-star-off';
        this.buttonText = '标记';
      }
    },
    // 打开计算器
    counter() {
      this.showCounter = !this.showCounter
    },
    // 来处理拖动开始事件
    handleDragStart(event) {
      if (event.button === 0) { // 检查鼠标左键按下
        this.isDragging = true;
        this.initialX = event.clientX - this.calculatorDraggable.offsetLeft;
        this.initialY = event.clientY - this.calculatorDraggable.offsetTop;
      }
    },
    // 计算偏移量并更新计算器的位置
    handleMouseMove(event) {
      // 判断是否处于拖拽状态
      if (this.isDragging) {
        const offsetX = event.clientX - this.initialX;  // 计算鼠标拖拽的X轴偏移量
        const offsetY = event.clientY - this.initialY;  // 计算鼠标拖拽的Y轴偏移量
        this.calculatorStyle.left = `${offsetX}px`;  // 设置计算器的left属性
        this.calculatorStyle.top = `${offsetY}px`;  // 设置计算器的top属性
      }
    },
    // 停止拖动
    handleMouseUp() {
      if (this.isDragging) {
        this.isDragging = false;
      }
    }
  },
}
</script>

<style scoped>
.calculator-draggable {
  position: fixed;
  left: 80%;
  top: 70%;
  transform: translate(-50%, -50%);
  cursor: move;
  z-index: 1000;
}

.calculator-draggable iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.box {
  background-color: #edfade;
  height: 52px;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  border: 2px solid #c1c1c1;
}

.left {
  display: flex;
  margin: 0 20px;
  align-items: center;
}

.prve {
  width: 100px;
  height: 26px;
  background-color: #1f81cf;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  margin: 0 20px;
  padding-top: 8px;
}

.next {
  width: 100px;
  height: 26px;
  background-color: #1f81cf;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  margin: 0 20px;
  padding-top: 8px;
}


.right {
  display: flex;
  margin: 0 20px;
  align-items: center;
}

.Markers {
  margin: 0 20px;
}
</style>
