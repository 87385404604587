<template>
  <div class="">
    <!-- 顶部信息 -->
    <topMessage ref="topMessageRef" :form="form" :item="item" :dataSource="dataSource"></topMessage>
    <div style="display: flex; justify-content: space-between;">

      <!-- 左侧数量信息 -->
      <leftMessage ref="leftMessageRef" :dataSource="dataSource" :currentQuestion="index" :markers="markers">
      </leftMessage>
      <div class="right-box">

        <!-- 题目信息 -->
        <topiceMessage ref="topiceMessageRef" @handleTopic="handleTopic" :dataSource="dataSource">
        </topiceMessage>

        <!-- 下方操作按钮 -->
        <belowBtn ref="belowBtnRef" :markers="markers" :dataSource="dataSource" @prev-clicked="prevQuestion"
          @next-clicked="nextQuestion" @markers-updated="dataBtn">
        </belowBtn>
      </div>
    </div>

    <footer class="footer">
      <a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备2021014802号</a>
    </footer>
  </div>
</template>

<script>
import topMessage from './modules/topMessage.vue'
import leftMessage from './modules/leftMessage.vue'
import belowBtn from './modules/belowBtn.vue'
import topiceMessage from './modules/topiceMessage.vue'
import { getTestInfo } from "@/api/course";


export default {
  name: 'answerPage',
  components: { topMessage, leftMessage, belowBtn, topiceMessage },
  data() {
    return {
      dataObj: {},
      dataSource: [],
      index: 0, // 当前题目索引
      chooseList: [], //做完题目的ID集合
      form: {}, // 传递给头部组件回显数据
      item: {}, // 传递给头部组件回显数据
      markers: false, // 标记状态
      isClick: false,
    }
  },
  mounted() {
    // 监听一个自定义事件 切题
    this.$bus.$on('event', (data) => {
      console.log('切题', data);
      this.index = data.index
      // left组件点击调用，更新题目的状态
      this.updateButton()
      // left组件点击调用，更新题目的状态
      // this.item = data.item || data;
    });
  },
  created() {
    this.subjectId = this.$route.query.item.id
    this.form = this.$route.query.form
    this.item = this.$route.query.item
    this.getSubject()
  },
  beforeDestory() {
    this.$bus.$off('event')
  },
  methods: {
    /**
    * 获得题目
    */
    getSubject() {
      getTestInfo(this.subjectId).then((res) => {
        console.log('res', res);
        this.dataSource = res.data.topics.map(item => {
          return {
            ...item,
            // 添加标记和是否已做的状态
            markers: this.markers,
            isClick: this.isClick
          };
        })
        // console.log('this.dataSourcethis.dataSource', this.dataSource);
        // 触发自定义事件并传递数据
        this.$emit('update-dataSource', this.dataSource);
      });
    },
    // 点击题目
    handleTopic() {
      this.$refs.leftMessageRef.goToNewQuestion()
    },
    // 上一题的索引
    prevQuestion() {
      if (this.index > 0) {
        this.index--;
        this.updateButton();
        // 多选题
        /* if (this.dataSource[this.index].type === 2) {
          console.log(this.dataSource[this.index]);
          // this.dataSource[this.index].id
        }
        // 简答题
        if (this.dataSource[this.index].type === 3) {
          console.log(this.dataSource[this.index]);
          let html = this.dataSource[this.index].briefAnswer
          console.log('html', html);
        } */
      }
    },
    // 下一题的索引
    nextQuestion() {
      if (this.index < this.dataSource.length - 1) {
        this.index++;
        this.updateButton();
        // 简答题
        /* if (this.dataSource[this.index].type === 3) {
          console.log(this.dataSource[this.index]);
          let html = this.dataSource[this.index].briefAnswer
          console.log('html', html);
        } */
      }
    },
    // 标签按钮
    dataBtn(data) {
      console.log('dadadadada', data);
      this.dataSource[this.index].markers = data
    },
    // 更新标记按钮的样式
    updateButton() {
      const belowBtnRef = this.$refs.belowBtnRef;
      console.log('belowBtnRef', belowBtnRef);
      // belowBtnRef.localMarkers 一直为true 需要点击两下才会再次更新，所以需要将markers赋值给他
      belowBtnRef.localMarkers = this.dataSource[this.index].markers
      if (belowBtnRef) {
        console.log('this.dataSource[this.index].markers', this.dataSource[this.index].markers);
        if (this.dataSource[this.index].markers) {
          belowBtnRef.buttonIcon = 'el-icon-star-on';
          belowBtnRef.buttonText = '取消标记';
        } else {
          belowBtnRef.buttonIcon = 'el-icon-star-off';
          belowBtnRef.buttonText = '标记';
        }
      }
    },
  },
}
</script>

<style scoped>
.right-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
