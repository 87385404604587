<template>
  <div class="option">
    <div style="margin: 5px 0 0 13px; color: #0075ff;">{{ type === 1 ? '单项选择题' : (type === 2 ? '多项选择题' : '简答题')
    }}</div>
    <ul class="option-options">
      <div v-for="(item, index) in dataSource" :key="index"
        :class="['option-option', item.isClick ? 'isSelect' : 'notSelect', { 'markers': item.markers }]"
        @click="goToNewQuestion(item, index)" :style="{ backgroundColor: item.id == questionNumberId ? '#f87218' : '' }">
        {{ index + 1 }}
      </div>
    </ul>
  </div>
</template>

<script>
import { getTestInfo } from "@/api/course";

export default {
  name: 'leftMessage',
  components: {},
  props: {
    dataSource: {
      type: Array
    },
    totalQuestions: {
      type: Number,
    },
    // 当前题目索引
    currentQuestion: {
      type: Number,
    },
    // 标记
    markers: {
      type: Boolean
    }
  },
  data() {
    return {
      subjectName: '一、单项选择题',
      questionNumberId: 0, // 当前展示的题目序号
      chooseList: [], //做完题目的ID集合
      selectedQuestionId: null, // 当前题目的id
      btnStyle: {},
      type: '',
    }
  },
  mounted() {
    console.log('this.markers', this.markers);
  },
  created() {
    this.$bus.$on('btnStyle', data => {
      console.log('btnStyle', data);
      this.btnStyle = data;
    })
  },
  watch: {
    dataSource(newVal, oldVal) {
      this.dataSource = newVal
      console.log('左侧this.dataSource', this.dataSource);
      // 左侧题目栏
      this.questionNumberId = this.dataSource[this.currentQuestion].id
      this.type = this.dataSource[this.currentQuestion].type
      // 使用 $nextTick 延迟发送事件的操作
      this.$nextTick(() => {
        // 发送事件，并传递新数据 右侧题目内容
        this.$bus.$emit('data-updated', this.dataSource[this.currentQuestion]);
      });
    },
    currentQuestion() {
      // 当 currentQuestion 属性变化时，重新计算题目内容
      this.updateQuestionContent();
    }
  },
  methods: {
    // 点击跳转到相应的题目
    goToNewQuestion(item, index) {
      console.log(item, index);
      this.questionNumberId = item.id
      // 传递给父页面
      this.$bus.$emit('event', { item, index });
    },
    updateQuestionContent() {
      // 根据当前题目索引获取对应的题目内容
      let item = this.dataSource[this.currentQuestion]
      this.questionNumberId = item.id
      // 传递给兄弟页面
      this.$bus.$emit('event', { item, index: this.currentQuestion });
    }
  },
}
</script>

<style scoped>
.option {
  width: 316px;
  height: 750px;
  background-color: #edfade;
  margin: 0 2px;
  padding: 3px 0;
  border-right: 2px solid #c1c1c1;
  overflow-y: auto;
}

.isSelect {
  background-color: #1d7dca;
  color: #fff;
}

.notSelect {
  background-color: white;
  color: #535353;
}

.option-options {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  .option-option {
    width: 35px;
    height: 35px;
    border: 1px solid #0075ff;
    border-radius: 3px;
    text-align: center;
    line-height: 35px;
    margin: 8px 5px 0 5px;
    cursor: pointer;
  }

  /* right-top */
  .markers {
    background-image: url('../../../assets/right-top.png');
    background-size: 15px 15px;
    background-repeat: no-repeat;
    background-position: top right;
  }

  /* .markers {
    background-image: url('../../../assets/markers.png');
    background-size: 15px 15px;
    background-repeat: no-repeat;
    background-position: top right;
  } */
}
</style>
