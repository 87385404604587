<template>
  <div>
    <div class="msg">
      <div class="left">
        <div class="avatar">
          头像
        </div>
        <div class="mesg">
          <span>{{ name }}</span>
          <span>{{ examNumber }}</span>
        </div>
        <div class="course">
          <span>{{ subject }}</span>
          <span>{{ subjectText }}</span>
        </div>
      </div>
      <div class="right" style="display: flex;">
        <div style="display: inline-grid;">
          <span>座位号：{{ seatNumber }}</span>
          <span> 剩余时间：{{ formattedTime }} </span>
        </div>
        <div class="btn" @click="onSubmitTestPaperConfirm">
          交卷
        </div>
      </div>

      <!-- 提示 -->
      <div style="display: none;">
        <div>
          <el-dialog title="信息提示" :visible.sync="visible" width="530px">
            <el-row>
              <el-col :sm="12" :lg="24">
                <div v-if="unfinished != '0'">
                  <el-result
                    icon="warning"
                    :title="`还有 ${unfinished} 道题未作答 请问是否交卷！`"
                  >
                    <template slot="extra">
                      <el-button type="primary" size="medium"
                        >确定交卷</el-button
                      >
                      <el-button
                        type="primary"
                        size="medium"
                        @click="visible = false"
                        >返回作答</el-button
                      >
                    </template>
                  </el-result>
                </div>
                <div v-else>
                  <el-result icon="info" title="确定要交卷吗？">
                    <template slot="extra">
                      <el-button type="primary" size="medium">确定</el-button>
                      <el-button
                        type="primary"
                        size="medium"
                        @click="visible = false"
                        >返回</el-button
                      >
                    </template>
                  </el-result>
                </div>
              </el-col>
            </el-row>
          </el-dialog>
        </div>
      </div>
    </div>

    <!-- 交卷后的弹窗 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="680px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="circle" style="height: 200px">
        <img
          src="../../../assets/circular1.png"
          width="54"
          height="54"
          class="circular1"
        />
        <img
          src="../../../assets/circular2.png"
          width="113"
          height="113"
          class="circular2"
        />
        <div class="center-img">
          <div>
            <div class="center-img-content">{{ correctRate }}%</div>
            <div class="center-img-text">正确率</div>
          </div>
        </div>
        <img
          src="../../../assets/circular3.png"
          width="19"
          height="19"
          class="circular3"
        />
        <img
          src="../../../assets/circular4.png"
          width="65"
          height="65"
          class="circular4"
        />
        <img
          src="../../../assets/circular5.png"
          width="35"
          height="35"
          class="circular5"
        />
      </div>
      <div class="dialog-center">
        <div class="circle-title">{{ result2.appTestVo.name }}</div>
        <div class="circle-statistics">
          <div class="circle-statistics-left">
            <div v-if="minute1" class="circle-statistics-title">
              {{ result2.useTime }}分钟
            </div>
            <div v-else class="circle-statistics-title">
              {{ result2.useTime }}秒
            </div>
            <div class="circle-statistics-text">答题时间</div>
          </div>
          <div class="circle-statistics-center"></div>
          <div class="circle-statistics-right">
            <div class="circle-statistics-title">{{ correctQuantity }}</div>
            <div class="circle-statistics-text">正确题目</div>
          </div>
        </div>
        <div class="divider"></div>
      </div>
      <div class="dialog-options">
        <ul class="option-options dialog-option">
          <div
            v-for="(item, index) in questionNumber2"
            :key="index"
            :class="[
              'option-option',
              item.correct == 0 ? 'error' : 'font-white',
            ]"
          >
            {{ item.num + 1 }}
          </div>
        </ul>
      </div>
      <el-pagination
        small
        layout="prev, pager, next"
        :page-size="36"
        :total="
          result2.appTestVo.topics != undefined
            ? result2.appTestVo.topics.length
            : 0
        "
        class="option-result-page"
        @current-change="currentChange2"
      >
      </el-pagination>
      <div class="view-all-analysis" @click="viewAllAnalysis()">
        查看全部解析
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { submitTest, getRecordInfo } from "@/api/course";

let int;
export default {
  name: "topMessage",
  components: {},
  props: {
    form: {
      type: Object,
    },
    item: {
      type: Object,
    },
    dataSource: {
      type: Array,
    },
  },
  data() {
    return {
      name: "",
      examNumber: "",
      subjectText: "",
      subject: "",
      seatNumber: "",
      visible: false, // 退出的提示弹窗
      unfinished: 0, //未完成的题目数量
      countdown: 3600, // 初始化倒计时时间为60分钟
      hour: "00", // 小时
      minutes: "00", // 分钟数
      seconds: "00", // 秒数
      subjectId: "", // 课程id
      result: "", // 选择的答案 A、B，C
      useTime: "00:00:00", // 用时
      formattedTime: "",
      type: "", //当前题目类型
      chooseList: [], //空数组
      data: [], // dataSource
      // 题目答完后的弹窗
      questionNumber2: [], // 答完题的题目列表序号
      minute1: true,
      result2: {
        appTestVo: {
          name: "",
        },
      },
      dialogVisible: false,
      correctRate: "", //正确率
      correctQuantity: "", // 题目正确数量
    };
  },
  mounted() {
    this.FormData();
    this.startCountdown();
  },
  created() {
    // this.$bus.$emit('un')
    this.subjectId = this.item.id;
    // 接收topic组件传过来的当前题目数据 用来判断题目类型 20231210
    this.$bus.$on("topicType", (data) => {
      this.type = data.type;
    });
    this.$bus.$on("dataSource", (data) => {
      // 接收topic组件传过来的所有题目的数组
      this.data = data;
    });
    this.$bus.$on("optionData", (data) => {
      // console.log("传过来的已做题目的", data);
      console.log(this.unfinished);
      if (this.type == 1 || this.type == 2) {
        // 单选题、多选题
        // 使用reduce方法来将具有相同ID的对象的答案合并到一个数组中
        // accumulator 参数表示累加器数组，持续更新函数
        const combinedAnswers = data.reduce((accumulator, topic) => {
          console.log("accumulator, topic", accumulator, topic);
          // 检查当前对象的 id 是否已经存在于 accumulator 数组中。如果存在，则我们将新的答案追加到现有答案后面，否则我们将新的对象添加到累加器数组中
          const existingAnswer = accumulator.find(
            (item) => item.id === topic.topicId
          );
          if (existingAnswer) {
            if (this.type == 2) {
              existingAnswer.opt += `、${topic.opt}`;
            } else {
              existingAnswer.opt = topic.opt;
            }
          } else {
            accumulator.push({ id: topic.topicId, opt: topic.opt });
          }
          return accumulator;
        }, []);
        console.log("combinedAnswers", combinedAnswers);
        // 使用map方法将每个对象转换为id: opt的格式后使用join方法将所有转换后的字符串连接起来，以逗号分隔
        // console.log("this.datathis.data", this.data);
        const result = this.data.map((item) => {
          // console.log("itemitemitem", item);
          const matchingAaa = combinedAnswers.find((res) => res.id === item.id);
          return {
            id: item.id,
            opt: matchingAaa ? (item.isClick ? matchingAaa.opt : "") : "", // 如果已完成，返回opt，否则返回空字符串
          };
        });
        // 使用 filter 方法筛选 opt 属性为空字符串的对象
        let unansweredQuestions = result.filter((item) => item.opt === "");
        // 获取未做的题目数量
        this.unfinished = unansweredQuestions.length;

        let newResult = result.map((item) => {
          // 将 opt 属性分割成数组
          let options = item.opt.split("、");
          // 使用 filter 方法去除重复的选项
          options = options.filter((option, index, self) => {
            // 检查当前选项是否只出现了一次
            return self.indexOf(option) === self.lastIndexOf(option);
          });
          // 使用 join 方法重新组合成字符串
          item.opt = options.join("、");
          return item;
        });

        const formattedResult = newResult.map((item) => {
          if (item.opt !== "") {
            return `${item.id}:${item.opt}`;
          } else {
            return `${item.id}: `;
          }
        });

        this.result = `${formattedResult.join(",")},`; // 对结果进行拼接
      } else {
        // 简答题
        // 使用reduce方法来将具有相同ID的对象的答案合并到一个数组中
        // accumulator 参数表示累加器数组，持续更新函数
        const combinedAnswers = data.reduce((accumulator, topic) => {
          // console.log('accumulator, topic', accumulator, topic);
          // 检查当前对象的 id 是否已经存在于 accumulator 数组中。如果存在，则我们将新的答案追加到现有答案后面，否则我们将新的对象添加到累加器数组中
          const existingAnswer = accumulator.find(
            (item) => item.id === topic.id
          );
          if (!existingAnswer) {
            accumulator.push({ id: topic.id, briefAnswer: topic.briefAnswer });
          }
          return accumulator;
        }, []);
        const result = this.data.map((item) => {
          console.log("item", item);
          const matchingAaa = combinedAnswers.find((res) => res.id === item.id);
          console.log("matchingAaa", matchingAaa);
          console.log("item.isClick", item.isClick);
          if (matchingAaa && item.isClick) {
            console.log(66666, matchingAaa.briefAnswer);
          }

          return {
            id: item.id,
            briefAnswer: matchingAaa
              ? item.isClick
                ? matchingAaa.briefAnswer
                : ""
              : "", // 如果已完成，返回briefAnswer，否则返回空字符串
          };
        });
        console.log("combinedAnswers", combinedAnswers);
        console.log("result", result);
        // 使用map方法将每个对象转换为id: opt的格式后使用join方法将所有转换后的字符串连接起来，以逗号分隔
        let res = result.map((item) => `${item.id}: ${item.briefAnswer}`);
        console.log("thresult", res);
        this.result = `${res.join(",")},`;
        // this.result = combinedAnswers.map(item => `${item.id}: ${item.briefAnswer}`).join(',');
        this.unfinished = this.data.length - combinedAnswers.length;
        console.log("简答题", this.unfinished);
      }
      console.log("this.result", this.result);
    });
  },
  beforeDestroy() {
    clearInterval(this.timer); // 在组件销毁前清除定时器，避免内存泄漏
    this.$bus.$off("topicType");
    this.$bus.$off("dataSource");
    this.$bus.$off("optionData");
  },
  watch: {
    dataSource(newVal, oldVal) {
      this.dataSource = newVal;
      console.log("this...", this.dataSource);
      if (this.result === "") {
        console.log("校验失败，this.result", this.result === "");
        // this.result = newVal.map(item => `${item.id
        let newData = [];
        this.dataSource.forEach((item) => {
          newData.push({
            id: item.id,
            opt: "",
          });
        });
        console.log("校验失败，生成了新数据源", newData);
        let result = newData.map((item) => `${item.id}: ${item.opt}`);
        this.result = `${result.join(",")},`;
        console.log("this.result", this.result);
      }
    },
  },
  methods: {
    // 计算时间
    startCountdown() {
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.minutes = Math.floor(+this.countdown / 60); // 计算剩余分钟数
          this.seconds = this.countdown % 60; // 计算剩余秒数
          this.countdown--;
          const hours = Math.floor(this.countdown / 3600);
          const minutes = Math.floor((this.countdown % 3600) / 60);
          const seconds = this.countdown % 60;
          // 将时间计算出来，如果是个位数时，则在前面加0
          this.formattedTime = `${hours
            .toString()
            .padStart(2, "0")} : ${minutes
            .toString()
            .padStart(2, "0")} : ${seconds.toString().padStart(2, "0")}`;
          // this.useTime = this.countdown - formattedTime;
          let time = 3600 - this.countdown; // 计算已用多长时间
          this.useTime = time.toString();
        } else {
          clearInterval(this.timer); // 当倒计时结束时清除定时器
        }
      }, 1000); // 每隔1秒执行一次
    },
    // 交卷
    onSubmitTestPaperConfirm() {
      console.log("this.unfinished", this.unfinished);
      if (this.unfinished == "0") {
        this.$confirm(`确定要交卷吗 ?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          showClose: false,
          center: true,
        }).then((res) => {
          submitTest({
            answer: this.result,
            testId: this.subjectId,
            useTime: this.useTime,
          }).then((res) => {
            console.log(res);
            if (res) {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.testRecordId = res.data.testRecordId;
              this.getTestResult(res.data.testRecordId);
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        });
      } else {
        this.$confirm(
          `还有 ${this.unfinished} 道题未作答，请确认是否交卷 ?`,
          "提示",
          {
            confirmButtonText: "确定交卷",
            cancelButtonText: "返回作答",
            type: "warning",
            showClose: false,
            center: true,
          }
        ).then((res) => {
          console.log("未做完,this.result", this.result === "");
          console.log("未做完,this.subjectId", this.subjectId);
          console.log("未做完,this.useTime", this.useTime);
          // this.result === ''
          submitTest({
            answer: this.result,
            testId: this.subjectId,
            useTime: this.useTime,
          }).then((res) => {
            console.log(res);
            if (res) {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.testRecordId = res.data.testRecordId;
              this.getTestResult(res.data.testRecordId);
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        });
      }
    },
    // 接收数据
    FormData() {
      // 将表单数据赋值给类变量
      this.name = this.form.name;
      this.examNumber = this.form.examNumber;
      this.subject = this.form.subject;
      this.seatNumber = this.form.seatNumber;
      this.subjectText = this.item.name;
      this.unfinished = this.item.topicNum;
    },
    currentChange2(page) {
      // 初始化问题编号数组
      this.questionNumber2 = [];
      // 计算结束页码
      var endPage = page * 36;
      // 计算开始页码
      var startPage = endPage - 36;
      // 获取topics数组
      var topi = this.result2.appTestVo.topics;
      // 遍历topics数组
      for (var i = 0; i < topi.length; i++) {
        // 如果当前页码大于等于开始页码且小于结束页码
        if (i >= startPage && i < endPage) {
          // 将当前页码和正确结果添加到问题编号数组中
          this.questionNumber2.push({
            num: i,
            correct: topi[i].testResultVo.correct,
          });
        }
      }
    },
    /**
     * 点击查看全部解析
     */
    viewAllAnalysis() {
      this.$router.push({
        path: "analysis",
        query: { id: this.testRecordId },
      });
    },
    /**
     * 获取测试的结果
     */
    getTestResult(id) {
      getRecordInfo({ id: id }).then((res) => {
        this.dialogVisible = true;
        this.result2 = res.data;
        this.result2.appTestVo.topics.forEach((item, index) => {
          if (index < 36) {
            this.questionNumber2.push({
              num: index,
              correct: item.testResultVo.correct,
            });
          }
        });
        this.result2 = res.data;
        console.log("this", this.result2);
        var i = 0;
        var number = {
          number: "",
        };
        res.data.appTestVo.topics.forEach(function(item, index) {
          number.number = index + 1;
          res.data.appTestVo.topics[index] = Object.assign(
            res.data.appTestVo.topics[index],
            number
          );
          if (item.testResultVo.correct === 1) i++;
        });
        // 所用时间不够一分钟用秒数
        if (res.data.useTime > 60) {
          res.data.useTime = (res.data.useTime / 60).toFixed(2);
          this.minute1 = true;
        } else {
          this.minute1 = false;
        }
        this.result2 = res.data;
        this.correctQuantity = i + "/" + res.data.appTestVo.topics.length;
        this.correctRate = (
          (i / res.data.appTestVo.topics.length) *
          100
        ).toFixed(0);
        if (this.correctRate > 60) {
          this.correctRateColor = "#6fddab";
        }
      });
    },
    // 关闭显示成绩的对话框
    handleClose() {
      this.$router.push({
        path: "questionBank",
      });
    },
  },
};
</script>

<style scoped>
.msg {
  line-height: 25px;
  display: flex;
  justify-content: space-between;
  background-color: #2e73b9;
  color: #fff;
  padding: 10px;
}

.left {
  display: flex;
}

.mesg {
  display: grid;
  margin: auto 20px;
}

.course {
  display: grid;
}

.btn {
  background-color: #e6a23c;
  align-self: flex-end;
  width: 80px;
  height: 23px;
  text-align: center;
  border-radius: 5px;
  margin: 0 20px;
  padding-top: 3px;
  cursor: pointer;
  line-height: 18px;
}

.option-result-page {
  text-align: center;
  margin-top: 130px;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 640px;
  height: 700px;

  .circular1 {
    position: absolute;
    left: 126px;
    top: 62px;
  }

  .circular2 {
    position: absolute;
    top: 162px;
    left: 135px;
  }

  .circular3 {
    position: absolute;
    top: 67px;
    right: 190px;
  }

  .circular4 {
    position: absolute;
    right: 150px;
  }

  .circular5 {
    position: absolute;
    top: 218px;
    right: 125px;
  }

  .center-img {
    width: 190px;
    height: 190px;
    color: #f2f2f2;
    /* background-image: url("../../assets/achievement.png"); */
    background-image: url("../../../assets/achievement.png");
    background-size: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .center-img-content {
      height: 45px;
      font-size: 32px;
      font-weight: bold;
      color: #ffffff;
    }

    .center-img-text {
      font-size: 13px;
      font-weight: bold;
      color: #fff;
      text-align: center;
    }
  }
}

.dialog-center {
  width: 470px;
  height: 145px;
  margin: 0 auto;

  .circle-title {
    text-align: center;
    margin-top: 20px;
    height: 28px;
    font-size: 20px;
    font-weight: 400;
    color: #acacac;
  }

  .circle-statistics {
    margin-top: 32px;
    display: flex;
    flex: 5;

    .circle-statistics-left {
      flex: 2;
      text-align: center;
      margin-top: 10px;
    }

    .circle-statistics-center {
      width: 1px;
      height: 70px;
      background-color: #ebebeb;
    }

    .circle-statistics-right {
      flex: 2;
      text-align: center;
      margin-top: 10px;
    }
  }
}

.circle-statistics-title {
  font-size: 18px;
  font-weight: 400;
  color: #000000;
}

.circle-statistics-text {
  font-size: 14px;
  font-weight: 400;
  color: #acacac;
  margin-top: 10px;
}

.divider {
  width: 471px;
  height: 1px;
  margin-top: 20px;
  background-color: #ebebeb;
}

.view-all-analysis {
  width: 84px;
  height: 20px;
  margin: 60px auto 40px auto;
  font-size: 14px;
  font-weight: 400;
  color: #4394ff;
  cursor: pointer;
}

.error {
  color: #ff6060;
  background-color: #ffcece;
}

.font-white {
  background-color: #b4d2ff;
  color: #438eff;
}

.option-options {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  .option-option {
    width: 35px;
    height: 35px;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    text-align: center;
    line-height: 35px;
    margin: 15px 8px 0 8px;
    cursor: pointer;
  }
}
</style>
